@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --radius: 0.5rem;
    --system-font: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
      Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    --system-font-mono: 'Source Code Pro', Menlo, Monaco, monospace;
  }

  :root,
  .light {
    --background: 0 0% 98%;
    --foreground: 0 0% 3.9%;

    --muted: 0deg 0% 96.1%;
    --muted-foreground: 0deg 0% 45.1%;

    --popover: 0deg 0% 100%;
    --popover-foreground: 0 0% 3.9%;

    --card: 0deg 0% 100%;
    --card-foreground: 0 0% 3.9%;

    --border: 0deg 0% 89.8%;
    --input: 0deg 0% 89.8%;

    --primary: 229 100% 62%;
    --primary-foreground: 0 0% 100%;

    --secondary: 0deg 0% 96%;
    --secondary-foreground: 0deg 0% 9%;

    --accent: 0deg 0% 96%;
    --accent-foreground: 0deg 0% 9%;

    --destructive: 358deg 75% 59%;
    --destructive-foreground: 351deg 89% 96%;

    --ring: 0deg 0% 63%;
  }

  :root {
    --success: 151deg 55% 41%;
    --success-foreground: 137deg 72% 94%;

    --warning: 24deg 94% 50%;
    --warning-foreground: 24deg 97% 93%;

    --info: 221.2 83.2% 53.3%;
    --info-foreground: 210 40% 98%;
  }

  .dark {
    color-scheme: dark;

    --background: 240 33.1% 5%;
    --foreground: 0 0% 98%;

    --muted: 201deg 6.2% 17.5%;
    --muted-foreground: 0 0% 63.9%;

    --popover: 240 17.1% 9%;
    --popover-foreground: 0 0% 98%;

    --card: 240 17.1% 9%;
    --card-foreground: 0 0% 98%;

    --border: 0 0% 14.9%;
    --input: 0 0% 14.9%;

    --primary: 229 100% 62%;
    --primary-foreground: 0 0% 100%;

    --secondary: 0 0% 14.9%;
    --secondary-foreground: 0 0% 98%;

    --accent: 0 0% 14.9%;
    --accent-foreground: 0 0% 98%;

    --destructive: 358deg 75% 59%;
    --destructive-foreground: 351deg 89% 96%;

    --success: 151deg 55% 41.5%;
    --success-foreground: 137 72% 94%;

    --warning: 24deg 94% 50%;
    --warning-foreground: 24deg 97% 93.2%;

    --info: 217.2 91.2% 59.8%;
    --info-foreground: 222.2 47.4% 11.2%;

    --ring: 0 0% 14.9%;
  }

  /* CHARTS */
  :root {
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
  }

  .dark {
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}

@layer base {
  /* BLUE */

  .light .theme_BLUE {
    --primary: 218 62% 47%;
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
  }

  .dark .theme_BLUE {
    --primary: 195 81% 66%;
    --primary-foreground: 222.2 47.4% 11.2%;
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
  }

  /* YELLOW */
  .light .theme_YELLOW {
    --primary: 47.9 95.8% 53.1%;
    --primary-foreground: 26 83.3% 14.1%;
    --secondary: 60 4.8% 95.9%;
    --secondary-foreground: 24 9.8% 10%;
  }

  .dark .theme_YELLOW {
    --primary: 47.9 95.8% 53.1%;
    --primary-foreground: 26 83.3% 14.1%;
    --secondary: 12 6.5% 15.1%;
    --secondary-foreground: 60 9.1% 97.8%;
  }

  /* ORANGE */
  .light .theme_ORANGE {
    --primary: 24.6 95% 53.1%;
    --primary-foreground: 60 9.1% 97.8%;
    --secondary: 60 4.8% 95.9%;
    --secondary-foreground: 24 9.8% 10%;
  }

  .dark .theme_ORANGE {
    --primary: 20.5 90.2% 48.2%;
    --primary-foreground: 60 9.1% 97.8%;
    --secondary: 12 6.5% 15.1%;
    --secondary-foreground: 60 9.1% 97.8%;
  }

  /* PURPLE */
  .light .theme_PURPLE {
    --primary: 262.1 83.3% 57.8%;
    --primary-foreground: 210 20% 98%;
    --secondary: 220 14.3% 95.9%;
    --secondary-foreground: 220.9 39.3% 11%;
  }

  .dark .theme_PURPLE {
    --primary: 263.4 70% 50.4%;
    --primary-foreground: 210 20% 98%;
    --secondary: 215 27.9% 16.9%;
    --secondary-foreground: 210 20% 98%;
  }

  /* RED */
  .light .theme_RED {
    --primary: 0 72.2% 50.6%;
    --primary-foreground: 0 85.7% 97.3%;
    --secondary: 0 0% 96.1%;
    --secondary-foreground: 0 0% 9%;
  }

  .dark .theme_RED {
    --primary: 0 72.2% 50.6%;
    --primary-foreground: 0 85.7% 97.3%;
    --secondary: 0 0% 14.9%;
    --secondary-foreground: 0 0% 98%;
  }

  /* GREEN */
  .light .theme_GREEN {
    --primary: 142.1 76.2% 36.3%;
    --primary-foreground: 355.7 100% 97.3%;
    --secondary: 240 4.8% 95.9%;
    --secondary-foreground: 240 5.9% 10%;
  }

  .dark .theme_GREEN {
    --primary: 142.1 70.6% 45.3%;
    --primary-foreground: 144.9 80.4% 10%;
    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 0 0% 98%;
  }
}

@layer base {
  * {
    scrollbar-width: thin;
    @apply border-border;
  }
  body {
    padding: 0;
    margin: 0;
    @apply bg-background text-foreground font-sans;
  }
}

@layer components {
  .grid-masonry {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: masonry; /* Please check the validity of the value `masonry` */
  }
}

.bg-scrollbar::-webkit-scrollbar {
  height: 8px;
  width: 8px;
  background: hsl(var(--background));
}

.bg-scrollbar::-webkit-scrollbar-thumb {
  background: hsl(var(--card));
  -webkit-border-radius: 1ex;
  -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
}

.bg-scrollbar::-webkit-scrollbar-corner {
  background: #000;
}

.paper-scrollbar::-webkit-scrollbar {
  height: 8px;
  width: 8px;
  background: hsl(var(--card));
}

option.multi-select-selected[selected] {
  background: hsl(var(--primary));
}

.SplitPane > span[role='presentation'] {
  width: 12px;
}

.giphy-wrapper > form > input {
  @apply text-foreground bg-card border-border border;
}

.dashboard-activity-grid {
  --pixel-size: 5px;

  @media (min-width: 400px) {
    --pixel-size: 8px;
  }

  @media (min-width: 768px) {
    --pixel-size: 10px;
  }

  @media (min-width: 1024px) {
    --pixel-size: 14px;
  }
}

.max-h-dialog {
  max-height: calc(100vh - 128px);
}

/* styles/globals.css */
.bg-dot-pattern {
  background-image: radial-gradient(
    circle,
    var(--dot-pattern, hsl(var(--foreground) / 0.3)) 1px,
    transparent 1px
  );
  background-size: var(--dot-size, 8px) var(--dot-size, 8px);
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.bg-dot-pattern:hover {
  opacity: 0.2;
}

.disable-lite-youtube-max-width lite-youtube {
  max-width: none !important;
}

mux-player {
  aspect-ratio: 16 / 9;
}

/**
  Prose.style.css
*/

/**

Placeholder of `MdxEditor`

MdxEditor library is very ugly and it's placeholder is buggy. This CSS fix all issues related to placeholder.

**/
.markdown-prose[class*='_placeholder_'] {
  @apply text-muted-foreground inset-x-0;
}

.force-prose-style > div {
  width: 100% !important;
  max-width: 100% !important;
  color: hsl(var(--foreground)) !important;
  @apply prose border border-foreground/20 w-full bg-transparent rounded-none border-dashed;
}
